.container {
    background-color: #264955;
    padding: 5px;
    width: 670px;
}

.headerPanel {
    background-color: #ffc;
    height: 24px;
    padding: 4px 5px;
    width: 660px;
}

.headerMessage {
    float: left;
    width: 405px;
}

.headerLinks {
    float: right;
    width: 245px;
    text-align: right;
}

.headerLinks span {
    cursor: pointer;
    color: blue;
    margin: 0 7px;
    text-decoration: underline;
}

.mainPanel {
    position: relative;
}

.brainPanel {
    width: 660px;
    padding: 10px 0;
    color: white;
    position: absolute;
}

.mainPanel:global(.large),
.brainPanel:global(.large) {
    height: 430px;
}

.mainPanel:global(.medium),
.brainPanel:global(.medium) {
    height: 330px;
}

.mainPanel:global(.small),
.brainPanel:global(.small) {
    height: 280px;
}

.brainViewY,
.brainViewX,
.brainViewZ,
.coordView {
    position: absolute;
}

.brainViewX:global(.large) {
    left: 184px;
}

.brainViewX:global(.medium) {
    left: 138.5px;
}

.brainViewX:global(.small) {
    left: 93px;
}

.brainViewZ:global(.large) {
    top: 194px;
}

.brainViewZ:global(.medium) {
    top: 148.5px;
}

.brainViewZ:global(.small) {
    top: 103px;
}

.coordView:global(.large) {
    top: 202px;
    left: 192px;
}

.coordView:global(.medium) {
    top: 158.5px;
    left: 146.5px;
}

.coordView:global(.small) {
    top: 111px;
    left: 101px;
}

.brainViewX canvas,
.brainViewY canvas,
.brainViewZ canvas {
    background-color: black;
}

.searchPanel {
    position: absolute;
    white-space: nowrap;
}

.searchPanel:global(.large) {
    top: 222px;
    left: 192px;
}

.searchPanel:global(.medium) {
    top: 178.5px;
    left: 146.5px;
}

.searchPanel:global(.small) {
    top: 131px;
    left: 101px;
}

.searchConditions {
    margin: 5px 0;
}

.searchButton {
    margin: 10px 0;
}

.searchConditions input[type="text"] {
    padding: 2px;
    border: 1px solid #ccc;
}

.searchConditions span:not(:first-child) {
    padding: 0 0 0 5px;
}

.popupPanel {
    position: absolute;
    right: 0;
    z-index: 1;
    width: 250px;
    padding: 5px;
    background-color: #ffc;
}

.settings {
    font-size: 85%;
}

.settings dl {
    margin: 0;
}

.settings dt {
    margin: 5px 0 3px;
    font-weight: bold;
}

.settings dd {
    margin: 0 0 0 5px;
    vertical-align: middle;
}

.settings dd span:not(:first-child) {
    margin: 0 0 0 5px;
}

.help {
    text-align: center;
}

.help div {
    margin: 5px 0 3px;
}

.help span {
    font-weight: bold;
}

.footerPanel {
    background-color: white;
}

.navTabs {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
}

.navTabs > li {
    width: 165px;
    margin-bottom: -1px;
    padding: 3px 10px;
    background-color: #e0e0e0;
    border: 1px solid #c0c0c0;
    cursor: pointer;
    text-align: center;
}

.navTabs > li:global(.actived) {
    background-color: white;
    font-weight: bold;
    border-bottom: none;
}

.tabContent {
    background-color: white;
    border: 1px solid #c0c0c0;
    padding: 10px 10px;
}

.tabBrainArea > div {
    float: left;
}

.tabBrainAreaSource,
.tabBrainAreaList {
    width: 265px;
}

.tabBrainAreaButtons {
    width: 108px;
    padding: 50px 0;
    text-align: center;
}

.tabBrainAreaSelect {
    margin: 5px 0;
    width: 265px;
}

.tabItemRetrievalTable th {
    background-color: #c0c0c0;
}

.tabItemRetrievalTable th:nth-child(2) {
    width: 500px;
}

.tabItemRetrievalTable td {
    background-color: #f5deb3;
}

.tabItemRetrievalTable > tbody > tr > td:first-child {
    font-weight: bold;
    text-align: center;
}
.tabItemRetrievalTable :global(.advancedSearchCondition:not(:first-child)) {
    margin: 5px 0;
}

.tabSearchResult a {
    color: #4169e1;
}

.tabSearchResultPageNavi {
    text-align: right;
    margin: 3px 0;
    font-weight: bold;
}

.tabSearchResultPageNavi span:not(:first-child),
.tabSearchResultPageNavi a:not(:first-child) {
    margin-left: 3px;
}

.tabSearchResultSortCondition {
    text-align: center;
    margin: 3px 0;
}

.tabSearchResultSortCondition span:not(:first-child) {
    margin-left: 3px;
}

.tabSearchResultItems li,
.tabMyItems li {
    border-bottom: 1px solid #90ee90;
}

.tabMyItems h3 {
    border-bottom: 1px solid #8b0000;
    border-left: 15px solid #8b0000;
    padding-left: 7px;
    color: #8b0000;
}

.listItem a {
    color: #4169e1;
    font-size: 110%;
    text-decoration: underline;
    font-weight: bold;
}

.listItem input[type="button"] {
    margin: 0 3px;
    padding: 0 3px;
}

.listItemCoordinates {
    margin-left: 20px;
}

.headerPanel::after,
.mainPanel::after,
.footerPanel::after,
.tabContent::after {
    content: "";
    display: block;
    clear: both;
}
