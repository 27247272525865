*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  min-width: 860px;
  max-width: 1500px;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  background-color: #fffff8;
  margin: 0;
  font-size: 80%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 1.802em;
}

h2 {
  font-size: 1.602em;
}

h3 {
  font-size: 1.424em;
}

h4 {
  font-size: 1.266em;
}

h5 {
  font-size: 1.125em;
}

h6 {
  font-size: 1em;
}

a {
  color: #ff6600;
  text-decoration: none;
}

a:hover {
  color: #ff0000;
  text-decoration: underline;
}

hr {
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cccccc;
  border-left: 0;
}

ul,
ol {
  margin: 0.5em 0;
  padding: 0 0.5em 0 2em;
}

li {
  line-height: 1.5;
  padding: 0.5em 0;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
}

td,
th {
  padding: 5px;
}

th,
td,
.head {
  vertical-align: top;
}

.foot {
  vertical-align: middle;
}

th,
.head,
.foot {
  font-weight: bold;
  background-color: #fef6ef;
  border-bottom: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
}

tr.even > td,
.even {
  background-color: #fdfaf7;
  border-bottom: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
}

tr.odd > td,
.odd {
  background-color: #ffffff;
  border-bottom: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
}

.outer {
  border: 1px solid #cacaca;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.hidden {
  display: none;
}

#page {
  margin: 0 auto;
  color: #666666;
  background: #fffff8 url(images/back.png) repeat-x;
}

#header {
  position: relative;
  height: 153px;
}

#header .logo {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 6px;
  height: 81px;
  width: 345px;
  background-image: url(images/logo.png);
}
#header .logo a {
  display: inline-block;
  height: 81px;
  width: 345px;
}
#header .jnode {
  position: absolute;
  right: 20px;
  top: 19px;
}
#header .menubar {
  position: absolute;
  left: 0;
  top: 92px;
}
#header .menubar ul.mainmenu {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
}
#header .menubar ul.mainmenu li {
  float: left;
  margin: 0;
  padding: 0;
}
#hmm01 {
  display: block;
  height: 54px;
  width: 159px;
  background: url(images/mmenu_01.png) no-repeat;
  text-indent: -9999px;
}
#hmm02 {
  display: block;
  height: 54px;
  width: 151px;
  background: url(images/mmenu_02.png) no-repeat;
  text-indent: -9999px;
}
#hmm03 {
  display: block;
  height: 54px;
  width: 160px;
  background: url(images/mmenu_03.png) no-repeat;
  text-indent: -9999px;
}
#hmm04 {
  display: block;
  height: 54px;
  width: 150px;
  background: url(images/mmenu_04.png) no-repeat;
  text-indent: -9999px;
}
#hsm01 {
  display: block;
  height: 54px;
  width: 60px;
  background: url(images/smenu_01.png) no-repeat;
  text-indent: -9999px;
}
#hsm03 {
  display: block;
  height: 54px;
  width: 58px;
  background: url(images/smenu_03.png) no-repeat;
  text-indent: -9999px;
}
#hsm04 {
  display: block;
  height: 54px;
  width: 58px;
  background: url(images/smenu_04.png) no-repeat;
  text-indent: -9999px;
}
#header .menubar ul.mainmenu li .lang {
  padding-top: 30px;
  margin-left: 7px;
}
#header .menubar ul.mainmenu li .lang a {
  margin: 0 3px;
}

#footer .pageTop {
  margin: 10px 10px 0;
  text-align: right;
}
#footer .pageTop a {
  display: inline-block;
  width: 65px;
  height: 19px;
  text-decoration: none;
  background: url(images/page_top.png) no-repeat;
}
#footer .link {
  text-align: right;
  padding: 5px 10px;
}
#footer .link span {
  margin-left: 10px;
}
#footer .copyright {
  padding: 16px 16px 30px;
  text-align: right;
  color: #ffcb00;
  background: #535353 url(images/footer_back.png) repeat-x;
}

.col2::after {
  content: "";
  display: block;
  clear: both;
}

#centercolumn {
  float: right;
  padding: 15px;
  width: 75%;
  min-height: 500px;
}

#leftcolumn {
  margin-top: 5px;
  float: left;
  overflow: hidden;
  width: 25%;
  max-width: 400px;
  background-color: #343434;
  border-radius: 0 10px 10px 0;
  padding: 8px 8px 8px 0;
}

.block:not(:first-child) {
  margin-top: 10px;
}

#leftcolumn .block .blockTitle {
  font-weight: bold;
  height: 27px;
  padding: 5px 10px 0 23px;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 3px 8px rgba(255, 255, 255, 0.5) inset;
  color: #fdfdfd;
  background: url(images/blockTitleIndent12.png) no-repeat 8px center #ef6e00;
  white-space: nowrap;
}

#leftcolumn .block .blockContent {
  margin-top: 3px;
  padding: 5px;
  background-color: #d7d7d7;
  color: #333333;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3) inset;
}

#leftcolumn .block .blockContent a {
  color: #333333;
}

#leftcolumn .block .blockContent a:hover {
  color: #ff6600;
  text-decoration: none;
}

#leftcolumn .block .blockContent ul.mainmenu {
  margin: 5px 0;
  padding-left: 0;
}
#leftcolumn .block .blockContent ul li {
  padding: 0;
}

#leftcolumn .block .blockContent .mainmenu a.menuMain {
  padding-left: 3px;
}

#leftcolumn .blockContent .mainmenu a,
#leftcolumn .blockContent .usermenu a {
  display: block;
  margin-bottom: 4px;
  padding: 3px;
  border-bottom: 1px dotted #aaa;
}

#centerCcolumn .block .blockTitle {
  font-weight: bold;
  height: 30px;
  padding: 8px 10px 0 28px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5),
    0 0 10px rgba(255, 255, 255, 0.5) inset;
  color: #fdfdfd;
  background: url(images/blockTitleIndent16.png) no-repeat 8px center #ef6e00;
  white-space: nowrap;
}

#centerCcolumn .block .blockContent {
  margin-top: 3px;
  padding: 5px;
}

#centerLcolumn {
  float: left;
  width: 49%;
}
#centerRcolumn {
  float: right;
  width: 49%;
}

#centerLcolumn .block .blockTitle,
#centerRcolumn .block .blockTitle {
  margin: 5px 0;
  padding: 3px 5px;
  color: #555555;
  font-weight: bold;
  font-size: 110%;
  background: url(images/blockTitleBack.png) repeat;
  white-space: nowrap;
}

#main h1 {
	background:url(images/h1_indent.png) no-repeat left center;
	padding: 5px 0 5px 33px;
}
#main h2 {
  background:url(images/h2_background.png);
  padding: 5px;
}