.heading {
    margin: 0 0 3px;
    font-size: 100%;
    font-weight: normal;
}

.item {
    display: flex;
    white-space: nowrap;
    width: 100%;
}

.order {
    margin-right: 5px;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
}

.count {
    margin-left: 10px;
}

.star {
    margin-left: 5px;
}
