.indexTree {
    border-top: 1px solid #999999;
    border-left: 1px solid #999999;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
    background-color: white;
    height: 400px;
    width: calc(100% - 6px);
    overflow: auto;
    margin: 0 auto;
    padding: 3px;
    line-height: 100%;
}

.formButton {
    margin: 3px 3px 10px;
}

.indexTree div span {
    color: #333;
    font-size: 12px;
    font-weight: bold;
    line-height: 19px;
    vertical-align: bottom;
}

.indexTree div span:hover {
    color: #f60;
}

.indexTree:global(.rc-tree .rc-tree-treenode) {
    line-height: 20px;
    white-space: nowrap;
}
.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-indent) {
    display: inline-block;
}
.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-indent .rc-tree-indent-unit) {
    display: inline-block;
    height: 20px;
    width: 9px;
    background: url(../assets/images/tree_line.png);
}
.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-indent .rc-tree-indent-unit:not(:last-child)) {
    background-position: -9px 0;
}
.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-indent .rc-tree-indent-unit:not(:last-child).rc-tree-indent-unit-end) {
    background-position: -18px 0;
}
.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-indent .rc-tree-indent-unit:last-child.rc-tree-indent-unit-end) {
    background-position: -27px 0;
}

.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-switcher) {
    display: inline-block;
    height: 20px;
    width: 16px;
    margin-right: 2px;
    background: url(../assets/images/tree_node.png);
    cursor: pointer;
}
.indexTree:global(.rc-tree .rc-tree-treenode:first-child .rc-tree-switcher.rc-tree-switcher-noop) {
    background-position: 0 0;
    cursor: auto;
}
.indexTree:global(.rc-tree .rc-tree-treenode:first-child .rc-tree-switcher.rc-tree-switcher_open) {
    background-position: -16px 0;
}
.indexTree:global(.rc-tree .rc-tree-treenode:first-child .rc-tree-switcher.rc-tree-switcher_close) {
    background-position: -32px 0;
}
.indexTree:global(.rc-tree .rc-tree-treenode:not(:first-child) .rc-tree-switcher.rc-tree-switcher-noop) {
    background-position: 0 -20px;
    cursor: auto;
}
.indexTree:global(.rc-tree .rc-tree-treenode:not(:first-child) .rc-tree-switcher.rc-tree-switcher_open) {
    background-position: -16px -20px;
}
.indexTree:global(.rc-tree .rc-tree-treenode:not(:first-child) .rc-tree-switcher.rc-tree-switcher_close) {
    background-position: -32px -20px;
}

.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper) {
    display: inline-block;
    height: 20px;
    cursor: pointer;
}
.indexTree:global(.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper .rc-tree-title) {
    vertical-align: middle;
    line-height: 20px;
}
